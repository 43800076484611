import { Group, Center, Text, Title, Badge } from '@mantine/core'
type CardProps = {
    title: string;
    description: string;
    builtWith: string[];
    link: string;
    github: string;
    format: string;
    uptimeLink: string;
    type: string;
    license: string;
    image: string;
    status: string[];
}
export default function Card(props: CardProps) {
    return (
        <div className="card">
            <Center>
                <a href={props.link} target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                    <Title className="linkBlack">{props.title}</Title>
                </a>
            </Center>
            <Text fz="small" sx={{opacity: "0.7"}}>{props.description}</Text>
            <a href={props.github} target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                <Title fz="small" sx={{opacity: "0.7"}} className="linkBlack">github: {props.github}</Title>
            </a>
            <Title fz="small" sx={{opacity: "0.7"}}>format: {props.format}</Title>
            <Title fz="small" sx={{opacity: "0.7"}}>type: {props.type}</Title>
            <Group sx={{marginTop: "5px"}} spacing="xs">
                {props.builtWith.map((item, index) => {
                    return (
                        <Badge key={index} color="gray" variant="outline">{item}</Badge>
                    )
                })}
                {props.status.map((item, index) => {
                    return (
                        <Badge key={index} color="dark" variant="outline">{item}</Badge>
                    )
                })}
            </Group>

        </div>
    )
}