export const projects = [
    {
        id: 1,
        title: "rezi",
        description: "a pirate game search engine. search for any game and get a link directly to the download page. fastest, largest, and prettiest pirate game search engine that is updated every 24 hours with the newest games.",
        builtWith: ["react", "meilisearch", "materialui", "python", "scrapy"],
        link: "https://rezi.one",
        github: "https://github.com/wamy-dev/reziwebsite",
        format: "website",
        uptimeLink: "https://search.rezi.one",
        type: "personal project",
        license: "foss",
        image: "",
        status: ["inactive development", "maintained", "automated database updates"]
    },
    {
        id: 2,
        title: "writeai",
        description: "an ai powered content generator. writeai can generate content from essays, to jokes, to code, to summaries and more. lead developer and co-founder. first project to bring in consistent revenue.",
        builtWith: ["react", "nodeJS", "express", "firebase", "mantineui", "openai", "digital ocean"],
        link: "https://writeai.net",
        github: "n/a",
        format: "website",
        uptimeLink: "https://writeai.net",
        type: "startup",
        license: "business",
        image: "",
        status: ["active development", "maintained"]
    }, 
    {
        id: 3,
        title: "mazi",
        description: "a discord bot that allows you to watch hosted plex movies and tv shows with your friends with little setup. utilizing the 'plex watch together' feature, you can easily start movie nights with your friends.",
        builtWith: ["react", "python", "discord.py", "flask"],
        link: "https://mazi.pw",
        github: "https://github.com/wamy-dev/mazi",
        format: "website + discord bot",
        uptimeLink: "https://api.mazi.pw",
        type: "personal project",
        license: "foss (bot only)",
        image: "",
        status: ["inactive development", "maintained"]
    },
    {
        id: 4,
        title: "leechersparadise",
        description: "a torrent and direct downloader that allows you to directly download from websites like 1fichier, rapidgator, youtube, and mega without having to pay for a premium account at extremely fast speeds. newer version of the original leechersparadise.",
        builtWith: ["react", "nodejs", "express", "firebase", "materialiu", "paypal"],
        link: "",
        github: "https://github.com/Wamy-Dev/LeechersParadise",
        format: "website",
        uptimeLink: "",
        type: "personal project",
        license: "foss (old version only)",
        image: "",
        status: ["inactive development"]
    },
    {
        id: 5, 
        title: "apokto",
        description: "an ios jailbreak repository that allows you to create your own custom lists of repositories. made for easy installing and removing of repository lists. updated every 24 hours with the newest jailbreak repositories.",
        builtWith: ["react", "nodejs", "express", "firebase", "canister.me"],
        link: "https://apokto.one",
        github: "https://github.com/wamy-dev/apokto",
        format: "website + ios jailbreak repository",
        uptimeLink: "https://api.apokto.one",
        type: "personal project",
        license: "foss",
        image: "",
        status: ["inactive development", "maintained", "automated database updates"]
    },
    {
        id: 6,
        title: "rugged",
        description: "rugged is a company that sells custom, handmade rugs.",
        builtWith: ["react", "threeJS", "cloudflare r3"],
        link: "https://rugged.business",
        github: "n/a",
        format: "Website",
        uptimeLink: "https://rugged.business",
        type: "client work",
        license: "business",
        image: "",
        status: ["inactive development"]
    },
    {
        id: 7,
        title: "prariegrovepeddler",
        description: "prariegrovepeddler is a company that sells custom quilts and quilt patterns as well as other fabric related products.",
        builtWith: ["react", "chakraUI", "cloudflare r3"],
        link: "https://prairiegrovepeddler.com",
        github: "n/a",
        format: "website",
        uptimeLink: "https://prairiegrovepeddler.com",
        type: "client work",
        license: "business",
        image: "",
        status: ["inactive development"]
    },
    {
        id: 8,
        title: "rakun",
        description: "a pirated anime, manga and ero search engine. search for any anime, manga or ero and get a link directly to the source. fastest, largest, and prettiest anime, manga and ero pirated search engine that is updated every 8 hours with the newest content.",
        builtWith: ["react", "meilisearch", "python", "fastapi", "scrapy"],
        link: "https://rakun.app",
        github: "https://github.com/Wamy-dev/Rakun",
        format: "website",
        uptimeLink: "https://api.rakun.app/status",
        type: "personal project",
        license: "foss",
        image: "",
        status: ["in development", "beta", "automated database updates"]
    }
]