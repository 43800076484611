import './App.css';
import { projects } from "./assets/projects";
import Card from "./components/card.tsx";
import Header from "./components/header.tsx";
import { Group, Center, Text, Grid, Title, Divider, Tooltip } from '@mantine/core'
import React, { useState, useEffect } from 'react';
//backgrounds
import Transition from "./assets/transition.svg";
//icons
import { IconArrowBadgeDownFilled, IconBrandSteam, IconListNumbers, IconTimelineEventExclamation, IconBrandDiscordFilled, IconFileCertificate, IconBrandLinkedin, IconBrandGithub, IconBrandTwitter, IconAt, IconBrandItch, IconNotebook } from '@tabler/icons-react'
function App() {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  useEffect(() => {
  fetch("https://api.github.com/users/Wamy-Dev/events/public")
    .then(response => response.json())
    .then(data => {
      data.slice().reverse().forEach(element => {
        if (element.type === "PushEvent") {
          var data = [element];
          let fullname = data[0].payload.commits[0].url.split("/repos/")[1].split("/commits/")[0];
          setName(fullname)
          const link = "https://github.com/" + fullname;
          setLink(link);
        }
        return data;
      });
      
    })
  }, []);
  return (
    <div className="App">
      <div style={{height: "100vh"}} className="blackContent">
        <Header />
        <Center sx={{paddingTop: "30vh"}}>
          <Text fz="small" sx={{color: "white"}}>i create things</Text>
        </Center>
        <Center sx={{ paddingTop: "30vh"}}>
          <a href="#main" style={{color: "white"}}>
            <IconArrowBadgeDownFilled size={30}/>
          </a>
        </Center>
      </div>  
      <div id="main" className="allContent" style={{height: "100vh", backgroundImage: `url(${Transition})`}}>
        <div style={{ paddingTop: "300px" }}>
          <Grid grow>
            <Grid.Col span={12}>
              <div className="card">
                <Text fw={700} sx={{opacity: "0.7"}}>my name is david novencido (no-ven-see-doe) and i am a developer of many kinds. i primarily do web development as well as backend development but i have been expanding recently to ios development as well as game development. i am a university student studying computer science. i like working on new and exciting things as well as older projects. i am now available for hire, as well as looking for jobs and internships.</Text>
              </div>
            </Grid.Col>
            <Grid.Col span={4}>
              <div className="card">
                <Text fw={700} sx={{textAlign: "center", opacity: "0.7"}}>my most recent repo i have committed to:</Text>
                  <a href={link}  style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                    <Title className="linkBlack" order={2}>{name}</Title>
                  </a>
                <Text fw={700} sx={{textAlign: "center", opacity: "0.7", paddingTop: "47%"}}>my most recent project i have started:</Text>
                <a href="https://rakun.app" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                  <Title className="linkBlack" order={2}>Rakun</Title>
                </a>
              </div>
            </Grid.Col>
            <Grid.Col span={8}>
              <div className="card">
                <Text fw={700} sx={{textAlign: "center", opacity: "0.7"}}>what i know:</Text>
                <Group position="center" direction="row" spacing="sm" sx={{paddingBottom: "10px"}}>
                  <Title order={2}>html</Title>
                  <Title order={3}>css</Title>
                  <Title>javascript</Title>
                  <Title order={2}>typescript</Title>
                  <Title>react</Title>
                  <Title>nodejs</Title>
                  <Title order={2}>express</Title>
                  <Title>python</Title>
                  <Title order={4}>fastapi</Title>
                  <Title order={4}>quart/flask</Title>
                  <Title order={2}>firebase</Title>
                  <Title order={3}>supabase</Title>
                </Group>
                <Divider/>
                <Group position="center" direction="row" spacing="sm" sx={{paddingTop: "10px", paddingBottom: "10px"}}>
                  <Title order={2}>docker</Title>
                  <Title order={4}>networking</Title>
                  <Title order={2}>linux</Title>
                  <Title order={3}>cloudflare</Title>
                  <Title order={3}>digitalocean</Title>
                  <Title order={3}>heroku</Title>
                  <Title order={5}>aws</Title>
                </Group>
                <Divider/>
                <Group position="center" direction="row" spacing="sm" sx={{paddingTop: "10px", paddingBottom: "10px"}}>
                  <Title order={3}>unity</Title>
                  <Title order={5}>c#</Title>
                  <Title order={6}>blender</Title>
                </Group>
                <Divider/>
                <Group position="center" direction="row" spacing="sm" sx={{paddingTop: "10px"}}>
                  <Title order={4}>c</Title>
                  <Title order={5}>swift</Title>
                </Group>
              </div>
            </Grid.Col>
            {projects.map((project) => (
              <Grid.Col span={4} key={project.id}>
                <Card 
                  title={project.title}
                  description={project.description}
                  link={project.link}
                  builtWith={project.builtWith}
                  status={project.status}
                  github={project.github}
                  format={project.format}
                  type={project.type}
                />
              </Grid.Col>
            ))}
            <Grid.Col span={12}>
              <div className="card">
                <Text fw={700} sx={{textAlign: "center", opacity: "0.7"}}>links:</Text>
                <Group position="center" direction="row" spacing="sm" sx={{paddingTop: "10px", paddingBottom: "10px"}}>
                  <Tooltip label="resume">
                    <a href="/resume.pdf" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconFileCertificate size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="linkedin">
                    <a href="https://www.linkedin.com/in/davidnovencido/" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandLinkedin size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="steam">
                    <a href="https://steamcommunity.com/id/RealWamy/" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandSteam size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="anime list">
                    <a href="https://anilist.co/user/Wamyy" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconListNumbers size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="project uptimes">
                    <a href="https://status.homeonacloud.com/" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconTimelineEventExclamation size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="discord server">
                    <a href="https://discord.gg/47SnjxgBFb/" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandDiscordFilled size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="github">
                    <a href="https://github.com/wamy-dev" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandGithub size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="twitter">
                    <a href="https://twitter.com/iamreallydavid" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandTwitter size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="email">
                    <a href="mailto:davidisadev@gmail.com" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconAt size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="itch">
                    <a href="https://wamyy.itch.io/" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconBrandItch size={30}/>
                    </a>
                  </Tooltip>
                  <Tooltip label="readcv">
                    <a href="https://read.cv/iamreallydavid" style={{color: "inherit", textAlign: "center", textDecoration: "none"}}>
                      <IconNotebook size={30}/>
                    </a>
                  </Tooltip>
                </Group>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </div>   
    </div>
  );
}

export default App;
