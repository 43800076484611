import { Group, Text } from '@mantine/core'
export default function Header() {
    return (
        <div className="header" style={{paddingRight: "10px", paddingLeft: "10px"}}>
            <Group spacing="sm" grow>
                <Text fz="small" sx={{color: "white"}}>david novencido</Text>
                <Group spacing="xs" grow>
                    <a href="https://twitter.com/iamreallydavid" target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                        <Text fz="small" className="link">twitter</Text>
                    </a>
                    <a href="mailto:davidisadev@gmail.com" target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                        <Text fz="small" className="link">email</Text>
                    </a>
                    <a href="https://github.com/wamy-dev" target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                        <Text fz="small" className="link">github</Text>
                    </a>
                    <a href="https://discord.gg/47SnjxgBFb" target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "none"}}>
                        <Text fz="small" className="link">discord</Text>
                    </a>
                </Group>
            </Group>
        </div>
    )
}